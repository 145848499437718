import genai from "../assets/images/posters/Design Posters/Workshops/Genai.jpg"
import robosoccer from "../assets/images/posters/Design Posters/Workshops/robosoccer.jpg"

const WorkshopDetails = [
    {
        eventname: "GenAI",
        image: genai,
        link: "https://forms.gle/hW1HxHrygGVApsS59"
    },
    {
        eventname: "Robo Soccer",
        image: robosoccer,
        link: ""
    }
]

export default WorkshopDetails;