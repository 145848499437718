import carnival from "../assets/images/posters/Design Posters/Non Tech/CARNIVAL.jpg";
import shadows from "../assets/images/posters/Design Posters/Non Tech/Shadows.png";
import kadhaipoma from "../assets/images/posters/Design Posters/Non Tech/Kadhaipoma.jpg";
import chess from "../assets/images/posters/Design Posters/Non Tech/Chess.jpg";
import duodynamics from "../assets/images/posters/Design Posters/Non Tech/Duo Dynamics.png";
import cricket from "../assets/images/posters/Design Posters/Non Tech/Cricket.jpg";
const NonTech = [
 
  {
    eventname: "Shadows of suspicious",
    image: shadows,
    link: "https://forms.gle/Ng21WoyuKrSHm9919",
  },
  {
    eventname: "Kadhaipoma",
    image: kadhaipoma,
    link: "https://forms.gle/igr1AxAxvT3rNXF96",
  },
  {
    eventname: "Duo Dynamics",
    image: duodynamics,
    link: "https://forms.gle/Mr2Nibm21Gwq23qq5",
  },
  {
    eventname: "Chess",
    image: chess,
    link: "",
  },
  {
    eventname: "cricket",
    image: cricket,
    link: "",
  },
  
  
  {
    eventname: "Carnival",
    image: carnival,
    link: "",
  },
];

export default NonTech;
