import bidopoly from "../assets/images/posters/Design Posters/Tech/Bidopoly.jpg";
import circuitclash from "../assets/images/posters/Design Posters/Tech/Circuit Clash.jpg";
import robos from "../assets/images/posters/Design Posters/Tech/Robo soccer.JPG"
const Tech = [
  {
    eventname: "Bidopoly",
    image: bidopoly,
    link:
      "https://forms.gle/q7H8VE23ZUJi5ktTA",
  },
  {
    eventname: "Circuit Clash",
    image: circuitclash,
    link:
      "https://forms.gle/mBmgsA3jd7wWtAqg6",
  },
  {
    eventname: "Robo soccer",
    image: robos,
    link:
      "https://forms.gle/xjJpC5z3J9m72nPQA",
  },
  
];

export default Tech;
