import abdul from "../assets/images/jointSecretaries/abdul.jpg";
import abinaya from "../assets/images/jointSecretaries/abinaya.jpg";
import anuvidhya from "../assets/images/jointSecretaries/anuvidhya.jpg";
import deena from "../assets/images/jointSecretaries/deena.jpg";
import divyadarshan from "../assets/images/jointSecretaries/divyadarshan.jpg";
import ebhin from "../assets/images/jointSecretaries/ebhin.jpg";
import hariish from "../assets/images/jointSecretaries/hariish.jpg";
import jeffery from "../assets/images/jointSecretaries/jeffery.jpg";
import priyadarshan from "../assets/images/jointSecretaries/priyadarshan.jpg";
import ragul from "../assets/images/jointSecretaries/ragul.jpg";
import sharmila from "../assets/images/jointSecretaries/sharmila.jpg";
import sivasujitha from "../assets/images/jointSecretaries/sivasujitha.jpg";
import sudhakar from "../assets/images/jointSecretaries/sudhakar.jpg";
import thiruvikesh from "../assets/images/jointSecretaries/thiruvikesh.jpg";
import visvarubasri from "../assets/images/jointSecretaries/visvarubasri.jpg";


const JsDetails = [
  {
    name: "Divya Darshan VR",
    designation: "Non Tech",
    photo: divyadarshan,
    instagram: "https://www.instagram.com/darshan_cruez?igsh=NGVhN2U2NjQ0Yg==",
    linkedin:"https://www.linkedin.com/in/divya-darshan-vr-2b4560289"
  },
  {
    name: "Visvarubasri P",
    designation: "Non Tech",
    photo: visvarubasri,
    instagram:"https://www.instagram.com/visvalaya_d_queens?igsh=MTRraWZzYnYwM2lnaw==			",
    linkedin:"https://www.linkedin.com/in/visvarubasri-p-14b04628b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Sudhakar G",
    designation: "Non Tech",
    photo: sudhakar,
    instagram:"https://www.instagram.com/think_diffrent_7113?igsh=MnQ4bGJteWtjMnUy			",
    linkedin:"https://www.linkedin.com/in/sudhakar-g-6ba16231b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Jeffery Samuel Raj P",
    designation: "Tech",
    photo: jeffery,
    instagram:"",
    linkedin:"https://www.linkedin.com/in/jeffery-samuel-raj-p-516553313/"
  },
  {
    name: "Hariish",
    designation: "Tech",
    photo: hariish,
    instagram:"https://www.instagram.com/hariish_s_?igsh=MXRjYW82dzAxaGQ5YQ==			",
    linkedin:"https://in.linkedin.com/in/hariish-s-7543852b5"
  },
  {
    name: "Ragul S",
    designation: "Tech",
    photo: ragul,
    instagram:"https://www.instagram.com/rahul_siva1234?igsh=cDl6YjFiOTl3aTUy		",
    linkedin:"https://www.linkedin.com/in/ragul-s-440908311?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Abinaya K",
    designation: "Workshop",
    photo: abinaya,
    instagram:"",
    linkedin:"https://www.linkedin.com/in/abinaya-k-64921831a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app						"
  },
  {
    name: "Deena Dhayalan GK",
    designation: "Workshop",
    photo: deena,
    instagram:"https://www.instagram.com/ideenadhayalan?igsh=MW9tdGk1bjZ0dHA3eA==",
    linkedin:"https://www.linkedin.com/in/deena-dhayalan-g-k-196a7b28b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Thiruvikesh B",
    designation: "Newsletter",
    photo: thiruvikesh,
    instagram:"https://www.instagram.com/thiruvikesh__?igsh=MTdtNGMxdDk4ZjRleg==",
    linkedin:"https://www.linkedin.com/in/thiruvikesh-b-569187282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Anuvidhya S",
    designation: "Newsletter",
    photo: anuvidhya,
    instagram:"https://www.instagram.com/an_vd_ya?igsh=anB3b3BkZTFvZmR4		",
    linkedin:"https://www.linkedin.com/in/anuvidhyaselvakumar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Ebhin Johnson J",
    designation: "Newsletter",
    photo: ebhin,
    instagram:"",
    linkedin:"https://www.linkedin.com/in/ebhin-johnson-j-17581b286?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Abdul Razzak A S",
    designation: "Career Guidance",
    photo: abdul,
    instagram:"https://www.instagram.com/abdulrazzak_alias_s_a_r?igsh=MWpmejEyaDY2OTIxZQ==			",
    linkedin:"https://www.linkedin.com/in/abdul-razzak-a-s-5a832b228?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Sharmila P R",
    designation: "Career Guidance",
    photo: sharmila,
    instagram:"https://www.instagram.com/sharm_ila0407?igsh=OTV0OTgyNWI0b2Nq			",
    linkedin:"https://www.linkedin.com/in/sharmila-pr-b6179531b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Siva Sujitha T",
    designation: "Documentation",
    photo: sivasujitha,
    instagram:"",
    linkedin:"https://www.linkedin.com/in/sujitha-thanagar-549651296?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app						"
  },
  {
    name: "Priyadharshan L",
    designation: "Documentation",
    photo: priyadarshan,
    instagram:"https://www.instagram.com/dharsh_2785?igsh=MXdnc3Z5cHM3ZGpsZg==			",
    linkedin:"https://www.linkedin.com/in/priyadharshan-l-9933a6306?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
];

export default JsDetails;
