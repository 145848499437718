import amirthaa from "../assets/images/organisingSecretaries/amirthaa.jpg";
import bharani from "../assets/images/organisingSecretaries/bharani.jpg";
import darshana from "../assets/images/organisingSecretaries/darshana.jpg";
import esakki from "../assets/images/organisingSecretaries/esakki.jpg";
import santhosh from "../assets/images/organisingSecretaries/santhosh.jpg";
import deepak from "../assets/images/organisingSecretaries/deepak.jpg";

const OsDetails = [
  {
    name: "Amirthaa P",
    designation: "Non Tech",
    photo: amirthaa,
    instagram:"https://www.instagram.com/amirthaa_prabakar?igsh=aXlhMWpoODJ2Ynkx			",
    linkedin:"https://www.linkedin.com/in/amirthaa-prabakar-ba8a9a314?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Bharani M",
    designation: "Tech",
    photo: bharani,
    instagram:"https://www.instagram.com/invites/contact/?igsh=1jffsgku58inr&utm_content=mwe4dp4			",
    linkedin:"https://www.linkedin.com/in/bharani-m"
  },
  {
    name: "Darshana R",
    designation: "Workshop",
    photo: darshana,
    instagram:"https://www.instagram.com/darshana_rajasekar?igsh=bGY5ZnltOG9mcmV3			",
    linkedin:"https://www.linkedin.com/in/darshana-rajasekar-07728627b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Esakki Rangaraj R",
    designation: "Newsletter",
    photo: esakki,
    instagram:"https://www.instagram.com/erango._?igsh=MXNsc2x3d2VkZzc4MQ==		",
    linkedin:"https://www.linkedin.com/in/esakki-rangaraj-50325a25b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Santosh SV",
    designation: "Career Guidance",
    photo: santhosh,
    instagram:"https://www.instagram.com/santosh1572004?igsh=M2Z2cmVlMjFjMXlj		",
    linkedin:"https://www.linkedin.com/in/santosh-s-v-714b6b2a1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Deepak R",
    designation: "Documentation",
    photo: deepak,
    instagram:"https://www.instagram.com/__deepak2525__?igsh=MWZqdTc1bXI4Znpjeg==			",
    linkedin:"https://www.linkedin.com/in/deepak-r-54251925a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  ,
];

export default OsDetails;
