import balaji from "../assets/images/officeBearers/balaji.jpeg";
import harsh from "../assets/images/officeBearers/harsh.jpg";
import jenifer from "../assets/images/officeBearers/jeni.jpg";
import lakshanya from "../assets/images/officeBearers/lakshanya.jpg";
import sivakumar from "../assets/images/officeBearers/sivakumar.jpg";
import suriya from "../assets/images/officeBearers/suriya.jpg";
import vishwajeith from "../assets/images/officeBearers/vishwajeith.jpg";


const ObDetails = [
  {
    name: "Harsh Kumar Doshi H",
    designation: "President",
    photo: harsh,
    instagram:"https://www.instagram.com/harshdoshi_14?igsh=MWEzYTczNWFqOGtveg==",
    linkedin:"https://in.linkedin.com/in/harsh-kumar-doshi"
  },
  {
    name: "Siva Kumar R V",
    designation: "Vice-President",
    photo: sivakumar,
    instagram:"https://www.instagram.com/plug_in_the_swag?igsh=dWk4c3ZmMGJ4azAy",
    linkedin:"https://www.linkedin.com/in/siva-rv-ece"
  },
  {
    name: "Lakshanya R",
    designation: "Vice-President",
    photo: lakshanya,
    instagram:"https://www.instagram.com/lakshanya_05?igsh=MXFiZXMxZHF4emd0",
    linkedin:"https://www.linkedin.com/in/lakshanya-ramachandran"
  },
  {
    name: "S Vishwajeith",
    designation: "Deputy Treasurer",
    photo: vishwajeith,
    instagram:"https://www.instagram.com/vishwandering_mind?igsh=c2J2OHBqZDI0a28y",
    linkedin:"https://www.linkedin.com/in/vishwajeith-s-7bba19243?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Suriya Prakash P",
    designation: "General Seceratry",
    photo: suriya,
    instagram:"https://www.instagram.com/suriya_.7/profilecard/?igsh=eWMxdjZ3NXlxeTVy",
    linkedin:"https://www.linkedin.com/in/suriyaprakash7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
  },
  {
    name: "Jenifer B",
    designation: "General Seceratry",
    photo: jenifer,
    instagram:"https://www.instagram.com/_jeni18_/",
    linkedin:"https://www.linkedin.com/in/jenifer-b-305636248/"
  },
  {
    name: "Balaji Prithviraj N",
    designation: "Genereal Secretary - PG",
    photo: balaji,
    instagram:"",
    linkedin:"https://www.linkedin.com/in/balaji-prithviraj-4a55a21b8?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B3VbESechTs60uPISyW%2B3AQ%3D%3D"
  },
  
];

export default ObDetails;
