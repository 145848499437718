import iim from "../assets/images/sponsors/2iim.jpg";
import barola from "../assets/images/sponsors/barola.jpg";


const daySponsors = [
  {
    name: "",
    link: "",
    image: iim,
  },
  {
    name: "",
    link: "",
    image: barola,
  },
];



export {
  daySponsors,
 
};
